@layer modules, ui, base;
@layer ui {
  /*  @define CardTitle */
/* stylelint-disable plugin/selector-bem-pattern */
.cardTitle_jds-CardTitle__link__joX8c,
.cardTitle_jds-CardTitle__link__joX8c:visited,
.cardTitle_jds-CardTitle__link__joX8c:hover,
.cardTitle_jds-CardTitle__link__joX8c:active,
.cardTitle_jds-CardTitle__link__joX8c:focus,
[class*="jds-Link"].cardTitle_jds-CardTitle__link__joX8c,
[class*="jds-Link"].cardTitle_jds-CardTitle__link__joX8c:visited,
[class*="jds-Link"].cardTitle_jds-CardTitle__link__joX8c:hover,
[class*="jds-Link"].cardTitle_jds-CardTitle__link__joX8c:active,
[class*="jds-Link"].cardTitle_jds-CardTitle__link__joX8c:focus {
  outline: 0;
  text-decoration: none;
}

.cardTitle_jds-CardTitle__link__joX8c {
  font-weight: var(--bold);
}

.cardTitle_jds-CardTitle__link__joX8c::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: thin solid var(--color-grey--200);
  border-radius: var(--Card-radius);
  box-shadow: none;
}

.cardTitle_jds-CardTitle__link__joX8c:hover::after {
  border-color: var(--color-grey--300);
  box-shadow: var(--hover-shadow);
}

.cardTitle_jds-CardTitle__link__joX8c:active::after {
  border-color: var(--color-grey--400);
}

.cardTitle_jds-CardTitle__link__joX8c:focus::after {
  --CardBorder: 2px;
  border-color: var(--color-grey--200);
  outline: var(--CardBorder) solid var(--color-grey--400);
  outline-offset: var(--CardBorder);
}

@media (min-width: 64rem) {
  .cardTitle_jds-CardTitle--big__tFhdl {
    font-size: var(--heading);
    line-height: var(--headingLineHeight);
  }
}

}
@layer ui {
  /* @define CardImage */

.cardImage_jds-CardImage__I_GO2 {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow: hidden;
  aspect-ratio: 16 / 9;
}

.cardImage_jds-CardImage__I_GO2::before {
  --CardImage-blurSize: 8px;

  content: "";
  position: absolute;
  z-index: 0;
  top: calc(var(--CardImage-blurSize) * -1);
  right: calc(var(--CardImage-blurSize) * -1);
  bottom: calc(var(--CardImage-blurSize) * -1);
  left: calc(var(--CardImage-blurSize) * -1);
  /* "--backgroundImageUrl" variable is defined at the html level (ex: <header style={{"--backgroundImageUrl": `url(url)`}}>) */
  background-image: var(--backgroundImageUrl);
  background-size: contain;
  filter: blur(var(--CardImage-blurSize)) brightness(75%);
}

.cardImage_jds-CardImage__image__jS8zd {
  position: absolute;
  top: 50%;
  width: 100%;
  height: auto;
  transform: translateY(-50%);
}

}
@layer ui {
  /* @define CardTagsListing */

.cardTagListing_jds-CardTagsListing__O6NOO {
  display: inline;
  margin: 0;
  padding: 0;
  list-style: none;
}

.cardTagListing_jds-CardTagsListing__item__WHxmt {
  display: inline-block;
  margin: 0 var(--spacer-100) var(--spacer-50) 0;
}

}
@layer ui {
  /* @define CardTime */

.cardTime_jds-CardTime__5StAm {
  display: block;
  margin-top: var(--spacer-200);
  color: var(--color-grey--700);
}

/* stylelint-disable-next-line plugin/selector-bem-pattern */
[class*="jds-Card--isDark"] .cardTime_jds-CardTime__5StAm {
  color: var(--color-grey--200);
}

@media (min-width: 48rem) {
  .cardTime_jds-CardTime__5StAm:not(.cardTime_jds-CardTime--isPush__yMi7n) {
    flex: 1 auto;
    align-self: flex-end;
    text-align: right;
  }
}

@media (min-width: 64rem) {
  .cardTime_jds-CardTime__5StAm:not(.cardTime_jds-CardTime--isPush__yMi7n) {
    flex: 1 0 auto;
  }
}

}
@layer ui {
  /* @define Listing */

.cardListing_jds-Listing__6rNno {
  display: block;
  margin: 0;
  padding-left: 0;
  list-style: none;
}

.cardListing_jds-Listing__Item__nw0fv {
  display: block;
}

.cardListing_jds-Listing__6rNno:not(.cardListing_jds-Listing--removeSpacing__fD5lO) .cardListing_jds-Listing__Item__nw0fv:not(:first-child) {
  margin-top: var(--spacer-200);
}

@media (min-width: 48rem) {
  .cardListing_jds-Listing__6rNno:not(.cardListing_jds-Listing--isPush__XYDKK) {
    display: block;
  }

  .cardListing_jds-Listing__6rNno:not(.cardListing_jds-Listing--isPush__XYDKK) .cardListing_jds-Listing__Item__nw0fv {
    display: inline;
    margin-bottom: 0;
  }

  .cardListing_jds-Listing__6rNno:not(.cardListing_jds-Listing--isPush__XYDKK) .cardListing_jds-Listing__Item__nw0fv > * {
    vertical-align: middle;
  }

  /* stylelint-disable-next-line plugin/selector-bem-pattern */
  .cardListing_jds-Listing__6rNno:not(.cardListing_jds-Listing--isPush__XYDKK) .cardListing_jds-Listing__Item__nw0fv:not(:last-child)::after {
    content: "";
    display: inline-block;
    width: 1px;
    height: 12px;
    margin: 0 var(--spacer-100);
    background: var(--color-grey--500);
    vertical-align: middle;
  }
}

}
@layer ui {
  /* @define CardSpaceBetween */

.cardSpacebetween_jds-CardSpaceBetween__6e4V3 {
  align-items: flex-start;
}

.cardSpacebetween_jds-CardSpaceBetween__6e4V3:not(.cardSpacebetween_jds-CardSpaceBetween--removeSpacing__OQDQz) {
  margin-top: var(--spacer-200);
}

/* stylelint-disable-next-line plugin/selector-bem-pattern */
.cardSpacebetween_jds-CardSpaceBetween__6e4V3 > *:not(:first-child) {
  margin-top: var(--spacer-200);
}

.cardSpacebetween_jds-CardSpaceBetween--hasDivider__mapyk {
  padding-top: var(--spacer-200);
  border-top: var(--border);
}

@media (min-width: 48rem) {
  .cardSpacebetween_jds-CardSpaceBetween__6e4V3:not(.cardSpacebetween_jds-CardSpaceBetween--isPush__TJ16i) {
    display: flex;
    justify-content: space-between;
    gap: var(--spacer-200);
  }

  /* stylelint-disable plugin/selector-bem-pattern */
  .cardSpacebetween_jds-CardSpaceBetween__6e4V3:not(.cardSpacebetween_jds-CardSpaceBetween--isPush__TJ16i) > *,
  .cardSpacebetween_jds-CardSpaceBetween__6e4V3:not(.cardSpacebetween_jds-CardSpaceBetween--isPush__TJ16i) > *:not(:first-child) {
    margin-top: 0;
  }
  /* stylelint-enable plugin/selector-bem-pattern */
}

}
@layer ui {
  /* @define CardFeedback */

.cardFeedback_jds-CardFeedback__Lr8jw {
  margin-top: var(--spacer-200);
  color: var(--mainColor);
  font-weight: var(--bold);
}

.cardFeedback_jds-CardFeedback__Text__aoaF2 {
  margin-right: var(--spacer-100);
  color: var(--color-black);
  vertical-align: middle;
}

}
@layer ui {
  /**  @define FilterBar **/

.filterBar_jds-FilterBar___Fpze {
  display: flex;
  flex-direction: column;
  box-shadow: var(--default-shadow);
}

.filterBar_jds-FilterBar__element__dSN3u {
  flex: 1;
  min-width: 0;
  margin: 0;
  margin-right: -1px;
  margin-bottom: -1px;
}

.filterBar_jds-FilterBar__element__dSN3u:hover,
.filterBar_jds-FilterBar__element__dSN3u:focus-within {
  z-index: 1;
}

.filterBar_jds-FilterBar__element__dSN3u:not(:first-child, :last-child) .jds-Select__control {
  border-radius: 0;
}

.filterBar_jds-FilterBar__element__dSN3u:first-child .jds-Select__control,
.filterBar_jds-FilterBar__element__dSN3u:first-child > div > input {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.filterBar_jds-FilterBar__element__dSN3u:last-child .jds-Select__control {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

@media (min-width: 48rem) {
  .filterBar_jds-FilterBar___Fpze {
    flex-direction: row;
  }

  .filterBar_jds-FilterBar__element__dSN3u:first-child .jds-Select__control,
  .filterBar_jds-FilterBar__element__dSN3u:first-child > div > input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: var(--radius-200);
  }

  .filterBar_jds-FilterBar__element__dSN3u:last-child .jds-Select__control {
    border-top-left-radius: 0;
    border-top-right-radius: var(--radius-200);
    border-bottom-left-radius: 0;
  }
}

}
@layer ui {
  /** @define Pagination */

.pagination_jds-Pagination__5KFrR {
  display: flex;
  justify-content: center;
  padding: 0;
  list-style-type: none;
}

.pagination_jds-Pagination__wrapper__wxN4Q {
  margin: var(--spacer-400) 0 var(--spacer-800) 0;
}

.pagination_jds-Pagination__item__55Rn3 {
  display: flex;
  align-items: center;
  margin-right: var(--spacer-200);
  text-align: center;
}

.pagination_jds-Pagination__item__55Rn3:last-child {
  margin-right: 0;
}

.pagination_jds-Pagination__5KFrR .pagination_jds-Pagination__button__GgDAw {
  background-color: transparent !important;
  color: var(--color-black) !important;
}

.pagination_jds-Pagination__5KFrR .pagination_jds-Pagination__button__GgDAw:hover {
  border-color: transparent;
  background-color: var(--color-grey--100) !important;
}

.pagination_jds-Pagination__5KFrR .pagination_jds-Pagination__activeButton__djART,
.pagination_jds-Pagination__5KFrR .pagination_jds-Pagination__button__GgDAw {
  min-width: 40px;
  border-color: transparent !important;
}

.pagination_jds-Pagination__5KFrR .pagination_jds-Pagination__activeButton__djART {
  color: var(--themeButtonTextColor, var(--color-black)) !important;
}

.pagination_jds-Pagination__5KFrR .pagination_jds-Pagination__button--disabled__e4_45 {
  color: var(--color-grey--200) !important;
}

}
@layer ui {
  .sk-Badge_main__AVFxz {
  --sk-Badge-y-padding: var(--sk-space-4);
  --sk-Badge-x-padding: var(--sk-space-8);
  --sk-Badge-label-spacing: var(--sk-space-4);
  --sk-Badge-height: calc(var(--sk-Badge-y-padding) * 2 + var(--sk-typography-data-display-badge-text-typography-m-line-height));

  display: inline-flex;
  box-sizing: border-box;
  flex-grow: 0;
  align-items: center;
  align-self: end;
  justify-content: center;
  min-width: 0; /* Required for overflow: ellipsis; */
  height: var(--sk-Badge-height);
  padding: var(--sk-Badge-y-padding) calc(var(--sk-Badge-x-padding) + var(--sk-Badge-label-spacing));
  overflow: hidden;
  border-radius: var(--sk-radius-20);
  font-family: var(--sk-typography-data-display-badge-text-typography-m-font-family);
  font-weight: var(--sk-typography-data-display-badge-text-typography-m-font-weight);
  gap: var(--sk-Badge-label-spacing);
}

.sk-Badge_label__f1crk {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.sk-Badge_icon__M8ilK {
  flex-shrink: 0;
}

.sk-Badge_main--withIcon__uFfRO {
  padding-right: calc(var(--sk-Badge-x-padding) + var(--sk-Badge-label-spacing));
  padding-left: var(--sk-Badge-x-padding);
}

.sk-Badge_main--medium__d1Pq_ {
  font-size: var(--sk-typography-data-display-badge-text-typography-m-font-size);
  font-weight: var(--sk-typography-data-display-badge-text-typography-m-font-weight);
  line-height: var(--sk-typography-data-display-badge-text-typography-m-line-height);
}

.sk-Badge_main--large__WUCcE {
  --sk-Badge-height: calc(var(--sk-Badge-y-padding) * 2 + var(--sk-typography-data-display-badge-text-typography-l-line-height));

  font-size: var(--sk-typography-data-display-badge-text-typography-l-font-size);
  font-weight: var(--sk-typography-data-display-badge-text-typography-l-font-weight);
  line-height: var(--sk-typography-data-display-badge-text-typography-l-line-height);
}

.sk-Badge_main--tertiary__J_3Du {
  border-width: var(--sk-data-display-badge-size-border-tertiary);
  border-style: solid;
}

.sk-Badge_main--light__Zj3_v.sk-Badge_main--primary__yAKxY {
  background-color: var(--sk-data-display-badge-color-background-primary-light);
  color: var(--sk-data-display-badge-color-primary-light);
}

.sk-Badge_main--light__Zj3_v.sk-Badge_main--primary__yAKxY.sk-Badge_isFaded__Mri4s {
  background-color: var(--sk-data-display-badge-color-background-primary-faded-light);
  color: var(--sk-data-display-badge-color-primary-faded-light);
}

.sk-Badge_main--light__Zj3_v.sk-Badge_main--secondary__wgMjW {
  background-color: var(--sk-data-display-badge-color-background-secondary-light);
  color: var(--sk-data-display-badge-color-secondary-light);
}

.sk-Badge_main--light__Zj3_v.sk-Badge_main--secondary__wgMjW.sk-Badge_isFaded__Mri4s {
  background-color: var(--sk-data-display-badge-color-background-secondary-faded-light);
  color: var(--sk-data-display-badge-color-secondary-faded-light);
}

.sk-Badge_main--light__Zj3_v.sk-Badge_main--tertiary__J_3Du {
  border-color: var(--sk-data-display-badge-color-border-tertiary-light);
  background-color: var(--sk-data-display-badge-color-background-tertiary-light);
  color: var(--sk-data-display-badge-color-tertiary-light);
}

.sk-Badge_main--light__Zj3_v.sk-Badge_main--success__3Jnl2 {
  background-color: var(--sk-data-display-badge-color-background-success-light);
  color: var(--sk-data-display-badge-color-success-light);
}

.sk-Badge_main--light__Zj3_v.sk-Badge_main--success__3Jnl2.sk-Badge_isFaded__Mri4s {
  background-color: var(--sk-data-display-badge-color-background-success-faded-light);
  color: var(--sk-data-display-badge-color-success-faded-light);
}

.sk-Badge_main--light__Zj3_v.sk-Badge_main--warning__x2jJg {
  background-color: var(--sk-data-display-badge-color-background-warning-light);
  color: var(--sk-data-display-badge-color-warning-light);
}

.sk-Badge_main--light__Zj3_v.sk-Badge_main--warning__x2jJg.sk-Badge_isFaded__Mri4s {
  background-color: var(--sk-data-display-badge-color-background-warning-faded-light);
  color: var(--sk-data-display-badge-color-warning-faded-light);
}

.sk-Badge_main--light__Zj3_v.sk-Badge_main--error__X5oen {
  background-color: var(--sk-data-display-badge-color-background-error-light);
  color: var(--sk-data-display-badge-color-error-light);
}

.sk-Badge_main--light__Zj3_v.sk-Badge_main--error__X5oen.sk-Badge_isFaded__Mri4s {
  background-color: var(--sk-data-display-badge-color-background-error-faded-light);
  color: var(--sk-data-display-badge-color-error-faded-light);
}

.sk-Badge_main--dark__p0R59.sk-Badge_main--primary__yAKxY {
  background-color: var(--sk-data-display-badge-color-background-primary-dark);
  color: var(--sk-data-display-badge-color-primary-dark);
}

.sk-Badge_main--dark__p0R59.sk-Badge_main--primary__yAKxY.sk-Badge_isFaded__Mri4s {
  background-color: var(--sk-data-display-badge-color-background-primary-faded-dark);
  color: var(--sk-data-display-badge-color-primary-faded-dark);
}

.sk-Badge_main--dark__p0R59.sk-Badge_main--secondary__wgMjW {
  background-color: var(--sk-data-display-badge-color-background-secondary-dark);
  color: var(--sk-data-display-badge-color-secondary-dark);
}

.sk-Badge_main--dark__p0R59.sk-Badge_main--secondary__wgMjW.sk-Badge_isFaded__Mri4s {
  background-color: var(--sk-data-display-badge-color-background-secondary-faded-dark);
  color: var(--sk-data-display-badge-color-secondary-faded-dark);
}

.sk-Badge_main--dark__p0R59.sk-Badge_main--tertiary__J_3Du {
  border-color: var(--sk-data-display-badge-color-border-tertiary-dark);
  background-color: var(--sk-data-display-badge-color-background-tertiary-dark);
  color: var(--sk-data-display-badge-color-tertiary-dark);
}

.sk-Badge_main--dark__p0R59.sk-Badge_main--success__3Jnl2 {
  background-color: var(--sk-data-display-badge-color-background-success-dark);
  color: var(--sk-data-display-badge-color-success-dark);
}

.sk-Badge_main--dark__p0R59.sk-Badge_main--success__3Jnl2.sk-Badge_isFaded__Mri4s {
  background-color: var(--sk-data-display-badge-color-background-success-faded-dark);
  color: var(--sk-data-display-badge-color-success-faded-dark);
}

.sk-Badge_main--dark__p0R59.sk-Badge_main--warning__x2jJg {
  background-color: var(--sk-data-display-badge-color-background-warning-dark);
  color: var(--sk-data-display-badge-color-warning-dark);
}

.sk-Badge_main--dark__p0R59.sk-Badge_main--warning__x2jJg.sk-Badge_isFaded__Mri4s {
  background-color: var(--sk-data-display-badge-color-background-warning-faded-dark);
  color: var(--sk-data-display-badge-color-warning-faded-dark);
}

.sk-Badge_main--dark__p0R59.sk-Badge_main--error__X5oen {
  background-color: var(--sk-data-display-badge-color-background-error-dark);
  color: var(--sk-data-display-badge-color-error-dark);
}

.sk-Badge_main--dark__p0R59.sk-Badge_main--error__X5oen.sk-Badge_isFaded__Mri4s {
  background-color: var(--sk-data-display-badge-color-background-error-faded-dark);
  color: var(--sk-data-display-badge-color-error-faded-dark);
}

}
@layer ui {
  /** @define Pictogram */
.Pictogram_jds-Pictogram__ZG8GF {
  fill: currentcolor;
  vertical-align: middle;
}

.Pictogram_jds-Pictogram--minor__wQY3C {
  width: var(--picto-size-minor); /* 36px */
  height: var(--picto-size-minor); /* 36px */
}

.Pictogram_jds-Pictogram--small__0SrfD {
  width: var(--picto-size-small); /* 48px */
  height: var(--picto-size-small); /* 48px */
}

.Pictogram_jds-Pictogram--medium__z2GTT {
  width: var(--picto-size-medium); /* 60px */
  height: var(--picto-size-medium); /* 60px */
}

.Pictogram_jds-Pictogram--large__TFbj1 {
  width: var(--picto-size-large); /* 96px */
  height: var(--picto-size-large); /* 96px */
}

.Pictogram_jds-Pictogram--major__PoFzE {
  width: var(--picto-size-major); /* 112px */
  height: var(--picto-size-major); /* 112px */
}

}
@layer ui {
  /** @define Input */

.input_jds-Input__x_yHZ {
  --Input-iconSize: 1.25rem;

  display: flex;
  box-sizing: border-box;
  align-items: center;
  width: 100%;
  min-height: var(--field-height);
  padding: 0 var(--spacer-200);
  border: thin solid var(--color-grey--200);
  border-radius: var(--radius-200);
  color: var(--color-black);
  font-family: inherit;
  font-size: var(--normalBody);
  font-weight: var(--normal);
  line-height: var(--normalBodyLineHeight);
}

.input_jds-Input--rightIcon__Hcje3 {
  padding-right: calc(var(--Input-iconSize) + var(--spacer-200) + var(--spacer-150));
}

.input_jds-Input--leftIcon__BsHKd {
  padding-left: calc(var(--Input-iconSize) + var(--spacer-200) + var(--spacer-150));
}

.input_jds-Input__x_yHZ:disabled {
  border-color: var(--color-grey--200);
  background-color: var(--color-grey--025);
  color: var(--color-grey--200);
}

.input_jds-Input__x_yHZ::-moz-placeholder {
  opacity: 1;
  color: var(--color-grey--500);
}

.input_jds-Input__x_yHZ::placeholder {
  opacity: 1;
  color: var(--color-grey--500);
}

.input_jds-Input__x_yHZ:disabled::-moz-placeholder {
  color: var(--color-grey--200);
}

.input_jds-Input__x_yHZ:disabled::placeholder {
  color: var(--color-grey--200);
}

.input_jds-Input__x_yHZ:not(:disabled):hover,
.input_jds-Input__x_yHZ:not(:disabled):focus {
  outline: 2px solid var(--color-grey--500);
  outline-offset: -1px;
}

.input_jds-Input--hasError__L_Tsh {
  border-color: var(--color-error--500);
}

.input_jds-Input--hasError__L_Tsh:not(:disabled):hover,
.input_jds-Input--hasError__L_Tsh:not(:disabled):focus {
  outline-color: var(--color-error--500);
}

}
@layer ui {
  /* @define Note */

.Note_jds-Note__SyY4K {
  display: flex;
  align-items: center;
  padding: var(--spacer-200) var(--spacer-200) var(--spacer-200) var(--spacer-300);
  gap: var(--spacer-200);
  border-radius: var(--radius-200);
}

.Note_jds-Note--loading__OPTYk {
  background-color: var(--color-grey--025) !important;
}

.Note_jds-Note--primary__tnFZB {
  background-color: var(--color-purple--100);
}

.Note_jds-Note--neutral__IOVI2 {
  background-color: var(--color-grey--050);
}

.Note_jds-Note--success__WSt9G {
  background-color: var(--color-success--100);
}

.Note_jds-Note--warning__hPqbt {
  background-color: var(--color-warning--100);
}

.Note_jds-Note--error__TXQcq {
  background-color: var(--color-error--100);
}

.Note_jds-Note__icon--success__v8HMq {
  color: var(--color-black);
}

.Note_jds-Note__icon--warning__8FwVO {
  color: var(--color-warning--500);
}

.Note_jds-Note__icon--error__Gx5Lw {
  color: var(--color-error--500);
}

.Note_jds-Note__body__TbCQO {
  flex: 1;
}

.Note_jds-Note__bodyTitle__PCf77 {
  font-weight: var(--semiBold);
}

.Note_jds-Note__closeButton__vLWj5 {
  align-self: flex-start;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
}

}
@layer ui {
  /** @Define Divider */

.divider_jds-Divider__v_d_b {
  margin: 0;
  border: 0;
  border-bottom: var(--border);
}

.divider_jds-Divider--white__D_HWR {
  border-bottom: var(--border--white);
}

}
@layer ui {
  .arrow_jds-arrow__GdEXX {
  display: none;
  z-index: 1 !important;
}

@media (min-width: 48rem) {
  .arrow_jds-arrow__GdEXX {
    /* Get the same display as the button with only icon */
    display: inline-flex;
    position: absolute;
    align-self: center;
    transform: translate(-50%, 0);
    border: none;
  }

  .arrow_jds-arrow__GdEXX:focus {
    outline: none !important;
  }

  .arrow_jds-arrow--hidden__tlIYW {
    display: none;
  }
}

}
@layer ui {
  .content_jds-content__separator__bOxyb {
  margin: 0 var(--spacer-100);
  background-color: var(--color-grey--100);
}

@media (min-width: 48rem) {
  .content_jds-content__wrapper__9e0A_ {
    position: relative;
    overflow-y: visible;
  }

  .content_jds-content__separator__bOxyb {
    margin: 0 var(--spacer-150);
  }
}

}
@layer ui {
  .header_jds-header--hidden__e3698 {
  opacity: 0;
}

}
@layer ui {
  /** @define Paper */

.paper_jds-Paper__W_bcw {
  padding: var(--spacer-300);
  background-color: var(--color-white);
  box-shadow: var(--default-shadow);
}

.paper_jds-Paper--isDark__BtGLj {
  background-color: var(--color-black);
  color: var(--color-white);
}

.paper_jds-Paper--noSpacing__5fBkQ {
  padding: 0;
}

.paper_jds-Paper--noShadow__6sUru {
  box-shadow: none;
}

.paper_jds-Paper--noBackground__9kbgQ {
  background-color: transparent;
}

.paper_jds-Paper--radius__qzXAo {
  border-radius: var(--radius-200);
}

.paper_jds-Paper--radiusRounded__GhgtS {
  border-radius: var(--radius-900);
}

.paper_jds-Paper--isFullWidth__pe_8P {
  padding-right: 0;
  padding-left: 0;
}

.paper_jds-Paper--hasBorder__DxYyT {
  border: var(--border);
}

@media (min-width: 48rem) {
  .paper_jds-Paper__W_bcw:not(.paper_jds-Paper--smallSpacing__GJYWV, .paper_jds-Paper--isFullWidth__pe_8P, .paper_jds-Paper--noSpacing__5fBkQ) {
    padding: var(--spacer-400) var(--spacer-600);
  }
}

}
@layer ui {
  /* @define FormTemplate */

.Section_jds-FormTemplate__section__fTP0Z {
  margin-top: var(--spacer-300);
}

.Section_jds-FormTemplate__section--withTitle__JaQ04 {
  margin-top: var(--spacer-600);
}

.Section_jds-FormTemplate__section--withTitle__JaQ04:first-of-type {
  margin-top: 0;
}

.Section_jds-FormTemplate__paperSection___1G5q {
  /* Override default Paper component padding. */
  padding: var(--spacer-400) !important;
}

/* stylelint-disable plugin/selector-bem-pattern */
.Section_jds-FormTemplate__paperSection___1G5q > *:last-child {
  margin-bottom: 0;
}

/* stylelint-enable plugin/selector-bem-pattern */

}
@layer ui {
  .item_jds-item--hidden__QPhMf {
  opacity: 0;
}

}
@layer ui {
  /** @define Status */

.status_jds-Status__I8m97 {
  display: inline-flex;
  align-items: center;
}

.status_jds-Status__I8m97::before {
  content: "";
  display: inline-block;
  flex-shrink: 0;
  width: var(--icon-size-tiny);
  height: var(--icon-size-tiny);
  margin-right: var(--spacer-100);
  border-radius: var(--radius-900);
  vertical-align: middle;
}

.status_jds-Status--active__nZ2DW::before {
  background-color: var(--color-success--500);
}

.status_jds-Status--inactive__yIA4M::before {
  background-color: var(--color-error--500);
}

.status_jds-Status--warning__e_U_q::before {
  background-color: var(--color-warning--500);
}

.status_jds-Status--neutral__wkb7R::before {
  background-color: var(--color-grey--200);
}

}
